export const CustomButtonStyles = {
  baseStyle: {},

  sized: {},

  variants: {
    primaryButton: () => ({
      bg: "primary",
      color: "white",
      _hover: {
        /**TODO : Change with gradient darken */
        bg: "primaryDarken",

        boxShadow: "xs",
      },
    }),
  },
};
