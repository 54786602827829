import { Box, Button, Container, Input, List, ListIcon, ListItem, Radio, RadioGroup, Stack, Tag, TagCloseButton, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Customer } from "../../utils/Types";
import { API_CLIENTS, API_URL } from "../../utils/api/ApiPaths";

export default function SearchCustomerMultiple({ onSelect }: any) {
  const [clients, setClients] = useState([]);
  const [recherche, setRecherche] = useState("");
  const [selectedClients, setSelectedClients] = useState<Customer[]>([]);
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [showClients, setShowClients] = useState(true);
  const [filterStatus, setFilterStatus] = useState(''); // 'active' or 'inactive'
  const toast = useToast();

  // récupérer les clients depuis l'API au montage du composant
  useEffect(() => {
    fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        // trier la liste des clients par ordre alphabétique
        const sortedClients = data.sort((a: Customer, b: Customer) =>
          a.first_name.localeCompare(b.first_name)
        );
        setClients(sortedClients);
      });
  }, []);


  const updateSelectedClients = (filter: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);  // Reset hours, minutes, seconds and milliseconds
  
    const newSelectedClients = clients.filter((client: any) => {
      console.log(client.appointments)
      if (filter === 'active') {
        // Check if client has at least one future appointment

        return client.appointments && client.appointments.some((appointment:any) => new Date(appointment.startDate) > today);
      } else if (filter === 'inactive') {
        // Check if client has no appointments or all are in the past
        return !client.appointments || client.appointments.every((appointment:any) => new Date(appointment.startDate) <= today);
      }
      return false;
    });
  
    setSelectedClients(newSelectedClients);
  };
  // filtrer les clients par recherche
  const clientsFiltres = clients.filter(
    (client: any) =>
      client.first_name.toLowerCase().includes(recherche.toLowerCase()) ||
      client.last_name.toLowerCase().includes(recherche.toLowerCase()) ||
      client.email.toLowerCase().includes(recherche.toLowerCase())
  );

  const handleSelectClient = (client: any) => {
    setSelectedClients((prev) => [...prev, client]);
    onSelect(client);
  };

  const handleContentChange = (content: string) => {
    setHtmlContent(content);
  }

  const handleSendEmails = () => {
    const selectedClientIds = selectedClients.map(client => client.id);
  
    fetch(API_URL + '/communication/send-emails', {  
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        clientIds: selectedClientIds,
        htmlContent: htmlContent,
      }),
      credentials: 'include',
    })
    .then(response => {
      if (response.ok) {
        toast({
          title: "Succès.",
          description: "Les emails ont été envoyés avec succès.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Erreur.",
          description: "Une erreur est survenue lors de l'envoi des emails.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    })
    .catch(error => {
      toast({
        title: "Erreur.",
        description: "Une erreur est survenue lors de l'envoi des emails.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }
  
  const handleRemoveClient = (clientToRemove: Customer) => {
    setSelectedClients(prev => prev.filter(client => client.id !== clientToRemove.id));
  };
  
  return (
    <>
        <Container py={{ base: "12", md: "12" }}>

<Box>
<RadioGroup
  onChange={(value) => {
    setFilterStatus(value as string);
    updateSelectedClients(value as string);
  }}
  value={filterStatus}
>
  <Stack direction="row">
    <Radio value="active">Clients actifs</Radio>
    <Radio value="inactive">Clients inactifs</Radio>
  </Stack>
</RadioGroup>
  {selectedClients.map((client, index) => (
    <Tag size="md" key={index} variant="solid" colorScheme="blue" m={2}>
      {client.first_name} {client.last_name}
      <TagCloseButton onClick={() => handleRemoveClient(client)} />
    </Tag>
  ))}
  <Input
    type="text"
    placeholder="Rechercher dans mes clients..."
    value={recherche}
    onChange={(e) => {
      setShowClients(true);
      setRecherche(e.target.value);
    }}
    onFocus={() => setShowClients(true)}
    onBlur={() => setTimeout(() => setShowClients(false), 200)}
  />
  {showClients && (
    <List mt={4} spacing={3}>
      {clientsFiltres
      .filter((client: Customer) => !selectedClients.find(selectedClient => selectedClient.id === client.id))
      .map((client: any) => (
          <ListItem
            key={client.id}
            onClick={() => handleSelectClient(client)}
            cursor="pointer"
          >
            <ListIcon as={FaUser} color="blue.500" />
            {client.first_name} {client.last_name}
          </ListItem>
        ))}
    </List>
  )}
</Box>

      <Box mt={4}>
        <ReactQuill theme="snow" value={htmlContent} onChange={handleContentChange} />
      </Box>
      <Button mt={4}       variant="primaryButton"
          color="white" onClick={handleSendEmails}>Envoyer à tous les clients sélectionnés</Button>
      </Container>
    </>
  );
}
