import FullCalendar from "@fullcalendar/react"; // must go before plugins

import { Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { EventApi } from "@fullcalendar/core";
import DayGridView from "@fullcalendar/daygrid";
import Interaction from "@fullcalendar/interaction";
import TimeGridView from "@fullcalendar/timegrid";
import { useEffect, useRef, useState } from "react";
import { FaRegStickyNote } from "react-icons/fa";
import {
  API_APPOINTMENTS,
  API_CLIENTS,
  API_ID_USERS,
  API_PLANNING,
  API_URL
} from "../../utils/api/ApiPaths";
import "./calendarStyles.css";
import Legend from "./Legend";

import AppointmentModalCreate from "./modal/ModalAppointmentCreate";
import DeleteAppointmentModal from "./modal/ModalDeleteAppointment";
import { ModalNotes } from "./modal/ModalNotes";
declare module "@fullcalendar/core" {
  interface PluginDef {
    dayGridView?: typeof DayGridView;
    interaction?: typeof Interaction;
    timeGridView?: typeof TimeGridView;
  }
}
declare module "@fullcalendar/react" {
  interface PluginDef {
    fullCalendar?: typeof FullCalendar;
  }
}
export interface SelectedIdState {
  idClient: string | null;
  idEvent: string | null;
}
interface Appointment {
  start: string;
  end: string;
  _id: string;
}

interface Event {
  clientid: string;
  name: string;
  appointments: Appointment[];
}

export default function Agenda() {
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpenNote, setIsModalOpenNote] = useState(false);
  const toast = useToast();

  const [selectedData, setSelectedData] = useState("");
  const [updateCountAgenda, setUpdateCountAgenda] = useState(0);
  const [clientAppointment, setClientAppointments] = useState([]);

  const [selectedId, setSelectedId] = useState<SelectedIdState>({
    idClient: null,
    idEvent: null,
  });
  const [selectedName, setSelectedName] = useState("");
  const [selectedNote, setSelectedNote] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);
  const calendarRef: any = useRef(null);
  const handleOpenNotes = async (clientId: any, name: any, data: any) => {
    // setSelectedName(name);
    //setSelectedData(data);
    //setSelectedId(id);
    //setIsModalOpenNote(true);
    try {
      console.log(data);
      const appointments = await getAppointmentsByClientId(clientId);
      setTitle(name);
      setSelectedData(data);
      setClientAppointments(appointments);
      setIsModalOpenNote(true);
    } catch (error) {
      console.error(error);
    }
  };
  const [dataCallendar, setDataCallendar] = useState<any>();

  const [title, setTitle] = useState("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [state, setState]: any = useState({});

  const handleSaveNotes = async (
    appointmentId: string,
    notes: string,
    selectedData: any
  ) => {
    // Enregistrez les notes dans votre état global ou base de données
    try {
      const updatedAppointment = await updateAppointment(
        appointmentId,
        notes,
        selectedData
      );

      console.log("Notes sauvegardées :", updatedAppointment);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des notes :", error);
    }
    setUpdateCountAgenda(updateCountAgenda + 1);
  };

  const updateAppointment = async (
    appointmentId: string,
    updatedNotes: string,
    selectedData: any
  ) => {
    try {
      const response = await fetch(
        API_URL +
          API_CLIENTS +
          "/" +
          selectedData.idClient +
          API_APPOINTMENTS +
          "/" +
          appointmentId,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ notes: updatedNotes }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update appointment: ${response.status} - ${response.statusText}`
        );
      }
      // Affichez un toast lorsque la modification est effectuée
      toast({
        title: "Notes sauvegardées",
        description: "Les notes ont été mises à jour avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      const responseData = await response.json();
      setUpdateCountAgenda(updateCountAgenda + 1);
      return responseData;
    } catch (error) {
      throw new Error(`Failed to update appointment: `);
    }
  };
  const createAppointment = async (
    data: any,
    newEvent: any,
    selectedClient?: any
  ) => {
    if (data.creationType === "existingCustomer") {
      console.log(JSON.stringify(newEvent));

      const response = await fetch(
        API_URL + API_PLANNING + "/" + selectedClient.id + API_APPOINTMENTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",

          body: JSON.stringify(newEvent),
        }
      );
      setUpdateCountAgenda(updateCountAgenda + 1);

      // ...
    } else if (data.creationType === "newCustomer") {
      // Créer un nouveau client
      const { type, type_absence, creationType, ...filteredData } = data;

      const customerResponse = await fetch(API_URL + API_CLIENTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(filteredData),
      });

      if (!customerResponse.ok) {
        throw new Error(
          `Failed to save customer: ${customerResponse.status} - ${customerResponse.statusText}`
        );
      }

      const newCustomerData = await customerResponse.json();
      const newCustomerId = newCustomerData.id; // Assurez-vous que l'ID du nouveau client est renvoyé par l'API

      // Créer un rendez-vous pour le nouveau client
      const appointmentResponse = await fetch(
        API_URL + API_PLANNING + "/" + newCustomerId + API_APPOINTMENTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(newEvent),
        }
      );

      if (!appointmentResponse.ok) {
        throw new Error(
          `Failed to save appointment: ${appointmentResponse.status} - ${appointmentResponse.statusText}`
        );
      }
      // Affichez un toast lorsque la modification est effectuée
      toast({
        title: "Nouveau rendez-vous ajouté",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Affichez un toast lorsque la modification est effectuée
      toast({
        title: "Nouveau client et rendez-vous ajouté",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setUpdateCountAgenda(updateCountAgenda + 1);
    } else if (data.creationType === "absence") {
      const response = await fetch(
        API_URL + API_PLANNING + API_ID_USERS + API_APPOINTMENTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",

          body: JSON.stringify(newEvent),
        }
      );

      // Affichez un toast lorsque la modification est effectuée
      toast({
        title: "Nouvelle absence ajoutée",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setUpdateCountAgenda(updateCountAgenda + 1);
    }

    /*
    const newEvent = {
      startDate: data.startDate,
      endDate: dateDefault,
    };

    // Add the new event to the state
    props.setDataCalendar([props.dataCalendar, newEvent]);

    // Post the new event to the API

    valueSend.forEach(async (value) => {
      const response = await fetch(
        API_URL + API_PLANNING + "/" + value.id + API_APPOINTMENTS,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",

          body: JSON.stringify(newEvent),
        }
      );
    });
    // Close the dialog
    props.setModal(false);
    setValueSend([]);
    */
  };

  const handleDeleteAppointment = async (objectid: SelectedIdState) => {
    console.log(objectid.idClient);
    console.log(objectid.idEvent);

    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        objectid?.idClient +
        API_APPOINTMENTS +
        "/" +
        objectid?.idEvent,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({}),
      }
    );
    if (!response.ok) {
      toast({
        title: "Suppression rdv",
        description: `La suppression a échoué.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      throw new Error(
        `Failed to delete appointment: ${response.status} - ${response.statusText}`
      );
    }
    toast({
      title: "Rendez-vous supprimé",
      description: `Le rendez-vous a été supprimé avec succès.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setUpdateCountAgenda(updateCountAgenda + 1);
    const responseData = await response.json();
    return responseData;
  };

  function getAppointmentColor(appointment: any) {
    console.log(appointment);
    if (appointment.type === "absence") {
      return "#6B6B6B";
    } else if (
      appointment.motif === "Première consultation" &&
      appointment.ado === "false"
    ) {
      return "#FF7575";
    } else if (
      appointment.motif === "Consultation Suivi" &&
      appointment.ado === "false"
    ) {
      return "#CBA415";
    } else if (
      appointment.motif === "Première consultation" &&
      appointment.ado === "true"
    ) {
      return "#9D00BD";
    } else if (
      appointment.motif === "Consultation Suivi" &&
      appointment.ado === "true"
    ) {
      return "#1377C3";
    } else {
      return "#2E5252"; // couleur par défaut
    }
  }

  const getAppointmentsByClientId = (clientId: any) => {
    return fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        const client = data.find((client: any) => client.id === clientId);

        if (!client) {
          throw new Error("Client not found");
        }

        return client.appointments.map((appointment: any) => {
          return {
            id: appointment._id,
            startDate: appointment.startDate,
            notes: appointment.notes, // Assurez-vous que 'notes' est bien la propriété correcte pour les notes
          };
        });
      });
  };

  useEffect(() => {
    // Fetch data from API

    fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        const transformedData = data.map((client: any) => {
          console.log("===", client);
          // Extrayez les données de chaque objet "appointments" ici
          return client.appointments.map(
            (appointment: {
              startDate: string;
              endDate: string;
              type: string;
              _id: string;
              motif: string;
              notes: string;
            }) => {
              return {
                idClient: client.id,
                title: client.first_name + " " + client.last_name,
                name: client.last_name,
                firstname: client.first_name,
                notes: appointment.notes,
                start: appointment.startDate,
                _id: appointment._id,
                end: appointment.endDate,
                type: appointment.type,
                motif: appointment.motif,
                color: getAppointmentColor(appointment),
              };
            }
          );
        });
        setDataCallendar(transformedData.flat());
      });

    /*
    const fetchData = async () => {
      const response = await fetch(API_URL + API_CLIENTS, {
        credentials: "include",
      });
      const data = await response.json();

      let datato: any = [
        {
          id: 1,
          title: "Timed event",
          start: "2022-12-12T13:00:00.000Z",
          end: "2022-12-12T13:30:00.000Z",
          // date: "2020-07-30"
        },
      ];
      datato = data.map((client: any) => ({
        id: 1,
        title: "Timed event",
        start: '2022-12-12T13:00:00.000Z"',
        end: "2022-12-12T13:30:00.000Z",
      }));

      console.log(datato);
      setDataCallendar(datato);
            */

    /*
      setDataCallendar([
        {
          id: 1,
          title: "Timed event",
          start: "2022-12-12T13:00:00.000Z",
          end: "2022-12-12T13:30:00.000Z",
          // date: "2020-07-30"
        },
      ]);
      */
    //};
    // fetchData();
  }, [updateCountAgenda]);

  const handleCloseModal = () => {
    handleClose();
    setModal(false);
    setIsModalOpenDelete(false);
    setIsModalOpenNote(false);
  };
  function handleClose() {
    setTitle("");
    setStart(new Date());
    setEnd(new Date());
    setState({});
    setModal(false);
  }

  function handleDateSelect(selectInfo: any) {
    // console.log(selectInfo.view.type);
    if (
      selectInfo.view.type === "timeGridWeek" ||
      selectInfo.view.type === "timeGridDay"
    ) {
      selectInfo.view.calendar.unselect();
      //setState({ selectInfo, state: "create" });
      // Open modal create
      setStart(selectInfo.start);
      setEnd(selectInfo.end);
      setModal(true);
    }
  }

  function renderEventContent(eventInfo: any) {
    console.log('====>Eventinfo')
    console.log(eventInfo);

    // Détection de la taille de l'écran (mobile)

    return (
      <Box className="event-content">
        <Flex direction="column" alignItems="flex-start">
          

    
          <Text className="firstname" noOfLines={1}>
          {eventInfo.event.extendedProps.name !== "Lerat" ?

            eventInfo.event.extendedProps.firstname
                    :
            "ABSENCE"
            }          
            </Text>
          
          <Text fontWeight="lighter" color="#f5f5f5">
          {eventInfo.event.extendedProps.name !== "Lerat" ?

          eventInfo.event.extendedProps.name
          :
          eventInfo.event.extendedProps.motif
          }          
           </Text>
              

          <Text mt="5%" color="#FFFCA8">
            {eventInfo.event.extendedProps.notes}
          </Text>
        </Flex>

        <Button
          className="button"
          size="md"
          onClick={(event) => {
            event.stopPropagation();
            handleOpenNotes(
              eventInfo.event.extendedProps.idClient,
              eventInfo.event.title,
              eventInfo.event.extendedProps
            );
          }}
        >
          <Icon as={FaRegStickyNote} color="black" />
        </Button>
      </Box>
    );
  }

  /**TODO */
  function handleEventClick(clickInfo: any) {
    console.log(clickInfo.event);
    setState({ clickInfo, state: "update" });
    // set detail
    setTitle(clickInfo.event.title);
    setStart(clickInfo.event.start);
    setEnd(clickInfo.event.end);
    setSelectedId({
      ...selectedId,
      idClient: clickInfo.event.extendedProps.idClient,
      idEvent: clickInfo.event.extendedProps._id,
    });

    console.log(clickInfo.event._def.extendedProps.idClient);
    setIsModalOpenDelete(true);
    // if (
    //   confirm(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove();
    // }
  }
  function handleEvents(events: any) {
    setCurrentEvents(events);
  }
  async function handleEventDrop(checkInfo: any) {
    // checkInfo.revert();
    const newEndDate = checkInfo.event.end.toISOString();
    const newStartDate = checkInfo.event.start.toISOString();
    /** Modif drop ajouter la nouvelle startDate */
    console.log(checkInfo.event.start.toISOString());

    setState({ checkInfo, state: "drop" });
    const idClient = checkInfo.event.extendedProps.idClient;
    const idAppointments = checkInfo.event.extendedProps._id;

    const sendDate = {
      start: newStartDate,
      end: newEndDate,
    };
    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        idClient +
        API_APPOINTMENTS +
        "/" +
        idAppointments,
      {
        method: "PUT",
        body: JSON.stringify({
          start: newStartDate,
          end: newEndDate,
        }),
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  async function handleEventResize(checkInfo: any) {
    /** Modif Resize ajouter les nouvelles dates */
    const newEndDate = checkInfo.event.end.toISOString();
    const newStartDate = checkInfo.event.start.toISOString();

    console.log(typeof newStartDate);

    const idClient = checkInfo.event.extendedProps.idClient;
    const idAppointments = checkInfo.event.extendedProps._id;

    console.log(newEndDate);
    console.log(newStartDate);

    const sendDate = {
      start: newStartDate,
      end: newEndDate,
    };
    const response = await fetch(
      API_URL +
        API_CLIENTS +
        "/" +
        idClient +
        API_APPOINTMENTS +
        "/" +
        idAppointments,
      {
        method: "PUT",
        body: JSON.stringify({
          start: newStartDate,
          end: newEndDate,
        }),
        credentials: "include",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(checkInfo);
    setState({ checkInfo, state: "resize" });

    setConfirmModal(true);
  }
  function handleEdit() {
    // console.log(start, end);
    // state.clickInfo.event.setAllDay(true);

    state.clickInfo.event.setStart(start);
    state.clickInfo.event.setEnd(end);
    state.clickInfo.event.mutate({
      standardProps: { title },
    });
    handleClose();
  }
  function handleSubmit() {
    // console.log(state.selectInfo.view.calendar);
    const newEvent = {
      id: 3,
      title,
      start: state.selectInfo?.startStr || start.toISOString(),
      end: state.selectInfo?.endStr || end.toISOString(),
      allDay: state.selectInfo?.allDay || false,
    };
    // console.log(newEvent);

    let calendarApi = calendarRef.current.getApi();
    // let calendarApi = selectInfo.view.calendar

    calendarApi.addEvent(newEvent);
    handleClose();
  }

  let todayStr = new Date().toISOString().replace(/T.*$/, "");

  return (
    <Box>
      <Box marginRight={10}>
        <div style={{ marginTop: "2%" }} className="calendar-container">
          <FullCalendar
            eventMinHeight={80}
            height="auto"
            ref={calendarRef}
            events={dataCallendar}
            plugins={[Interaction, DayGridView, TimeGridView]}
            firstDay={1}
            slotMinTime="07:00"
            slotMaxTime="22:00"
            slotDuration="01:00"
            headerToolbar={{
              left: "prev,today,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            buttonText={{
              today: "Aujourd'hui",
              month: "Mois",
              week: "Semaine",
              day: "Jour",
              list: "Liste",
            }}
            locale="fr"
            initialView="timeGridWeek"
            timeZone="local"
            editable={true}
            selectable={true}
            allDaySlot={false}
            selectMirror={true}
            dayMaxEvents={true}
            nowIndicator={true}
            weekends={weekendsVisible}
            select={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            eventAdd={(e) => {
              console.log("eventAdd", e);
            }}
            eventChange={(e) => {
              console.log("eventChange", e);
            }}
            eventRemove={(e) => {
              console.log("eventRemove", e);
            }}
          />
        </div>
      </Box>

      <AppointmentModalCreate
        isOpen={modal}
        tmpStartDate={start}
        tmpEndDate={end}
        setStartDate={setStart}
        setEndDate={setEnd}
        onClose={handleCloseModal}
        onSave={createAppointment}
      />

      <DeleteAppointmentModal
        isOpen={isModalOpenDelete}
        onClose={handleCloseModal}
        onDelete={handleDeleteAppointment}
        profilName={title}
        selectedId={selectedId}
      />
      <ModalNotes
        isOpen={isModalOpenNote}
        onClose={() => setIsModalOpenNote(false)}
        onSave={handleSaveNotes}
        profilName={title}
        selectedData={selectedData}
        appointments={clientAppointment}
      />

      <Legend />
    </Box>
  );
}
