import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";

// ...

export default function DeleteCustomerModal({
  isOpen,
  onClose,
  onDelete,
  customer,
}: any) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);
    onDelete(customer);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <DeleteIcon color="red" /> Supprimer le client {customer?.first_name}{" "}
          {customer?.last_name} ?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Êtes-vous sûr de vouloir supprimer ce client ? Cette action est
          irréversible.
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Annuler
          </Button>
          <Button
            colorScheme="red"
            isLoading={isLoading}
            onClick={handleDelete}
          >
            Supprimer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
