import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { useForm } from "react-hook-form";
import { FaCalendarAlt, FaExclamationCircle } from "react-icons/fa";

import * as yup from "yup";
import { Appointments } from "../../../utils/Types";
import SearchCustomer from "../SearchCustomer";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    appointment: Appointments,
    newEvent: any,
    selectedClient?: any
  ) => void;
  tmpStartDate: any;
  tmpEndDate: any;
  setStartDate: any;
  setEndDate: any;
};

const schema = yup.object().shape({
  creationType: yup.string(),
  first_name: yup.string().notRequired(),
  last_name: yup.string().notRequired(),
  email: yup.string().email("Le format de l'email est invalide").notRequired(),
  phone_number: yup.string().notRequired(),
  gender: yup.string().notRequired(),
  type: yup.string().notRequired(),
  maiden_name: yup.string().notRequired(),
  birthdate: yup.string().notRequired(),
  address: yup.string().notRequired(),
  city: yup.string().notRequired(),
  zipcode: yup.string().notRequired(),
  start_date: yup.string().notRequired(),
  end_date: yup.string().notRequired(),
});

const AppointmentModalCreate: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  tmpStartDate,
  tmpEndDate,
  setStartDate,
  setEndDate,
}) => {
  const genderOptions = [{label : "Masculin", value: "M."}, {label: "Feminin", value: "Mme"}, {label: "Autres", value: "Mme/M."}];
  const motifOptions = ["Consultation Suivi", "Première consultation"];
  const [selectedClient, setSelectedClient] = useState(null);

  const [newCustomer, setNewCustomer] = useState(false);
  const [ado, setAdo] = useState(false);
  const [creationType, setCreationType] = useState("existingCustomer");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const handleSelectClient = (client: any) => {
    setSelectedClient(client);
  };
  const timeZone = "Europe/Paris";

  const formatDateTimeToParis = (dateTime: any) => {
    const parisTimezone = timeZone;
    const zonedStartDateTime = utcToZonedTime(dateTime, parisTimezone);
    return zonedStartDateTime;
  };

  useEffect(() => {
    // Calculez la nouvelle valeur de endDate en fonction de la valeur de ado
    const newEndDate = new Date(tmpStartDate);
    if (ado) {
      newEndDate.setHours(newEndDate.getHours() + 1);
    } else {
      newEndDate.setHours(newEndDate.getHours() + 1);
      newEndDate.setMinutes(newEndDate.getMinutes() + 15);
    }

    // Mettre à jour la valeur de endDate
    setEndDate(newEndDate);
  }, [ado, tmpStartDate]);

  const onSubmit = (data: any) => {
    const newEvent = {
      startDate: tmpStartDate,
      endDate: tmpEndDate,
      type: creationType,
      new_client: newCustomer.toString(),
      ado: ado.toString(),
      motif: creationType === "absence" ?  data.type_absence: data.type,
      notes: "",
    };

    const dataWithCreationType = {
      ...data,
      creationType,
    };
    console.log("===>", dataWithCreationType);

    if (creationType === "existingCustomer") {
      onSave(dataWithCreationType, newEvent, selectedClient);
      handleClose();
      // ...
    } else if (creationType === "newCustomer") {
      onSave(dataWithCreationType, newEvent, selectedClient);
      handleClose();
    } else if (creationType === "absence") {
      onSave(dataWithCreationType, newEvent, selectedClient);

      handleClose();
      // ...
    }
  };

  const handleClose = () => {
    setSelectedClient(null);
    setNewCustomer(false);
    setAdo(false);
    setCreationType("existingCustomer");
    reset(); // Réinitialiser le formulaire

    onClose();
  };

  {
    /**  TOTO : 
  Delete
  EDIT
  NOTES
 */
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "90vw", md: "40vw" }}
      >
        <ModalHeader>Ajouter un rendez-vous</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Tabs
              onChange={(index) => {
                switch (index) {
                  case 0:
                    setCreationType("existingCustomer");
                    setNewCustomer(false);
                    break;
                  case 1:
                    setCreationType("absence");
                    setNewCustomer(false);
                    break;
                  default:
                    setCreationType("existingCustomer");
                    setNewCustomer(false);
                }
              }}
            >
              <TabList>
                <Tab flex="1" gap={2}>
                  {" "}
                  <FaCalendarAlt />
                  Rendez-vous
                </Tab>
                <Tab color="yellow.500" flex="1" gap={2}>
                  <FaExclamationCircle />
                  Absence
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing={4}>
                    <Checkbox
                      onChange={(e) => {
                        setNewCustomer(e.target.checked);
                        setCreationType("newCustomer");
                      }}
                    >
                      Nouveau client ?
                    </Checkbox>
                    {newCustomer ? (
                      <Box>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "1fr 1fr",
                            lg: "1fr 1fr",
                          }}
                          gap={4}
                        >
                          <FormControl isInvalid={!!errors.first_name}>
                            <FormLabel htmlFor="first_name">* Prénom</FormLabel>
                            <Input type="text" {...register("first_name")} />
                          </FormControl>
                          <FormControl isInvalid={!!errors.last_name}>
                            <FormLabel htmlFor="last_name">* Nom</FormLabel>
                            <Input type="text" {...register("last_name")} />
                          </FormControl>
                        </Grid>

                        <FormControl isInvalid={!!errors.maiden_name}>
                          <FormLabel>Nom de jeune fille</FormLabel>
                          <Input {...register("maiden_name")} />
                        </FormControl>

                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "1fr 1fr",
                            lg: "1fr 1fr",
                          }}
                          gap={4}
                        >
                          <FormControl isInvalid={!!errors.email}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Input type="email" {...register("email")} />
                          </FormControl>
                          <FormControl isInvalid={!!errors.phone_number}>
                            <FormLabel htmlFor="phone_number">
                              Téléphone
                            </FormLabel>
                            <Input type="tel" {...register("phone_number")} />
                          </FormControl>
                        </Grid>

                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "1fr 1fr",
                            lg: "1fr 1fr",
                          }}
                          gap={4}
                        >
                          <FormControl isInvalid={!!errors.birthdate}>
                            <FormLabel>Date de naissance</FormLabel>
                            <Input type="date" {...register("birthdate")} />
                          </FormControl>
                          <FormControl isInvalid={!!errors.gender}>
                            <FormLabel>Genre</FormLabel>
                            <Select {...register("gender")} variant="filled">
                              <option value="">Choisir une option</option>
                              {genderOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <FormControl isInvalid={!!errors.address}>
                          <FormLabel>Adresse</FormLabel>
                          <Input {...register("address")} />
                        </FormControl>

                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "1fr 1fr",
                            lg: "1fr 1fr",
                          }}
                          gap={4}
                        >
                          <FormControl isInvalid={!!errors.city}>
                            <FormLabel>Ville</FormLabel>
                            <Input {...register("city")} />
                          </FormControl>

                          <FormControl isInvalid={!!errors.zipcode}>
                            <FormLabel>Code postal</FormLabel>
                            <Input {...register("zipcode")} />
                          </FormControl>
                        </Grid>
                      </Box>
                    ) : (
                      <Box>
                        <SearchCustomer onSelect={handleSelectClient} />
                      </Box>
                    )}
                    <Checkbox onChange={(e) => setAdo(e.target.checked)}>
                      Ado / Enfant ?
                    </Checkbox>
                    <FormControl isInvalid={!!errors.type}>
                      <FormLabel>Motif</FormLabel>
                      <Select {...register("type")} variant="filled">
                        <option value="">Choisir une option</option>
                        {motifOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>{" "}
                    </FormControl>
                    <Grid
                      templateColumns={{
                        base: "1fr",
                        md: "1fr 1fr",
                        lg: "1fr 1fr",
                      }}
                      gap={4}
                    >
                      <FormControl isInvalid={!!errors.start_date} mt="4">
                        <FormLabel>Date de début</FormLabel>
                        <DateTimePicker
                          onChange={setStartDate}
                          value={tmpStartDate}
                        />
                      </FormControl>

                      <FormControl isInvalid={!!errors.end_date} mt="4">
                        <FormLabel>Date de fin</FormLabel>
                        <DateTimePicker
                          onChange={setEndDate}
                          value={tmpEndDate}
                        />
                      </FormControl>
                    </Grid>
                  </Stack>
                </TabPanel>
                <TabPanel>
                  {/** ABSENCE */}
                  <FormControl isInvalid={!!errors.type_absence}>
                    <FormLabel>Motif de l'absence</FormLabel>
                    <Input {...register("type_absence")} />
                  </FormControl>

                  <Grid
                    templateColumns={{
                      base: "1fr",
                      md: "1fr 1fr",
                      lg: "1fr 1fr",
                    }}
                    gap={4}
                  >
                    <FormControl isInvalid={!!errors.start_date} mt="4">
                      <FormLabel>Date de début</FormLabel>
                      <DateTimePicker
                        onChange={setStartDate}
                        value={tmpStartDate}
                      />
                    </FormControl>
                    <FormControl isInvalid={!!errors.end_date} mt="4">
                      <FormLabel>Date de fin</FormLabel>
                      <DateTimePicker
                        onChange={setEndDate}
                        value={tmpEndDate}
                      />
                    </FormControl>
                  </Grid>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="outline" borderColor={"red"}>
              Annuler
            </Button>
            <Button variant="primaryButton" color="white" ml={3} type="submit">
              Ajouter
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default AppointmentModalCreate;
