import { Box, Container, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Appointments } from '../../utils/Types';
import { API_URL } from '../../utils/api/ApiPaths';

// Définition du type Notification
interface Notification {
  _id: string;
  clientId: string;
  content: string;
  date: string;
  appointments: Appointments;
}

const NotificationTable: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Récupérer les notifications depuis l'API
  useEffect(() => {
    fetch(API_URL+ '/notifications') // Remplacer par l'URL de votre API
      .then(response => response.json())
      .then(data => {
        const sortedNotifications = data.sort(
          (a: Notification, b: Notification) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        setNotifications(sortedNotifications);
      })
      .catch(error => {
        console.error('Could not fetch notifications:', error);
      });
  }, []);

  function getAppointmentColor(appointments: any) {
    const appointment = appointments[0]
    console.log(appointment);
    if (appointment.type === "absence") {
      return "#6B6B6B";
    } else if (
      appointment.motif === "Première consultation" &&
      appointment.ado === "false"
    ) {
      return "#FF7575";
    } else if (
      appointment.motif === "Consultation Suivi" &&
      appointment.ado === "false"
    ) {
      return "#CBA415";
    } else if (
      appointment.motif === "Première consultation" &&
      appointment.ado === "true"
    ) {
      return "#9D00BD";
    } else if (
      appointment.motif === "Consultation Suivi" &&
      appointment.ado === "true"
    ) {
      return "#1377C3";
    } else {
      return "#2E5252"; // couleur par défaut
    }
  }

  return (
    <Container py={{ base: "12", md: "12" }}>

    <Box overflowX="auto">
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
          <Th></Th>
          <Th>Date de création</Th>
          <Th>Description</Th>
          </Tr>
        </Thead>
        <Tbody>
          {notifications.map(notification => (
            <Tr key={notification._id}>
                            <Td>
                  <Box
                    width="20px"
                    height="20px"
                    bg={getAppointmentColor(notification?.appointments)}
                  />
                </Td>
              <Td>{new Date(notification.date).toLocaleString()}</Td>
              <Td><Text isTruncated>{notification.content}</Text></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
    </Container>
  );
};

export default NotificationTable;
