// Navigation
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
// Error handling
// Components

// Chakra IMPORTS

// theming

// COMPONENT
const PublicLayout = () => {
  // auth

  // RETURN
  return (
    <Box sx={{ display: "flex" }} className="PublicLayout">
      {/* APP BAR */}
      <Box sx={{ flexGrow: 1, pt: 8, px: 1 }}>
        {/* PAGES */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default PublicLayout;
