// api paths
export const API_URL: string = process.env.REACT_APP_API_base_url as string;
export const API_LOGIN: string = process.env.REACT_APP_API_login as string;
export const API_LOGOUT: string = process.env.REACT_APP_API_logout as string;
export const API_CLIENTS: string = process.env.REACT_APP_API_clients as string;
export const API_PLANNING: string = process.env
  .REACT_APP_API_planning as string;
export const API_APPOINTMENTS: string = process.env
  .REACT_APP_API_appointments as string;
export const API_USERS: string = process.env.REACT_APP_API_users as string;
export const API_ID_USERS: string = process.env.REACT_APP_API_id as string;
