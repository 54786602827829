import { theme as proTheme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";

import { CustomButtonStyles as Button } from "../modules/customComponents/ButtonStyles";
export const customTheme = extendTheme(proTheme, {
  fonts: {
    ...proTheme.fonts.heading,
    heading: "'Montserrat', -apple-system, system-ui, sans-serif",
    body: "'Roboto', -apple-system, system-ui, sans-serif",
  },

  colors: {
    primary: "#2E5252",
    primaryDarken: "#203939",

    secondary: "#fef7e8",

    brand: {
      50: "#2E5252",
      100: "#2E5252",
      200: "#2E5252",
      300: "#2E5252",
      400: "#2E5252",

      //color Primary (checkbox, button ....)
      500: "#2E5252",

      600: "#2E5252",
      700: "#2E5252",
      800: "#2E5252",

      //Color Heading
      900: "#2E5252",
    },
  },

  components: {
    Button,
  },
});
