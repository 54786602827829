import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Logopdb } from "../../modules/customComponents/LogoPdb";
import AuthService from "../../utils/authentication/Service";
import { ToLoginType } from "../../utils/Types";
import { loginValidationSchema } from "../../utils/validation/authentification/ValidationSchema";

export const Login = () => {
  const toast = useToast();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ToLoginType>({
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async (data: ToLoginType) => {
    setIsSubmitting(true);

    try {
      const response = await AuthService.login(data);
      setIsSubmitting(false);
      navigate("/");
      toast({
        title: "Connexion réussie",
        description: "Bienvenue sur espace " + response.user.email,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      toast({
        title: "Erreur",
        description: "Erreur serveur, email ou mot de passe incorrect",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="6">
            {/** TODO:  Logo in svg comming */}
            <Logopdb />
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading
                size={useBreakpointValue({ base: "xs", md: "sm" })}
                color="brand.900"
              >
                Connexion à l'interface d'administration de
              </Heading>
              <Text color="muted"></Text>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isRequired isInvalid={!!errors.email?.message}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  type="email"
                  id="email"
                  placeholder="Email"
                  {...register("email")}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="password"
                isRequired
                isInvalid={!!errors?.password?.message}
              >
                <FormLabel htmlFor="password">Mot de passe</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="********"
                    {...register("password")}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack spacing="4">
              <Button isLoading={isSubmitting} type="submit" variant="primary">
                Se connecter
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
};
