import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { IconType } from "react-icons";
import { FaCalendar, FaEnvelope, FaHistory, FaUsers } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { NOTIF_PAGE, RELATION_PAGE } from "../../utils/navigation/endpoints";
import { Logo } from "./Logo";

interface LinkItemProps {
  name: string;
  icon: IconType;
  page: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "AGENDA", icon: FaCalendar, page: "/" },
  { name: "CLIENTS", icon: FaUsers, page: "/clients" },
  { name: "RELATION", icon: FaEnvelope, page: RELATION_PAGE },
  { name: "HISTORIQUE", icon: FaHistory, page: NOTIF_PAGE },

];

export default function SimpleSidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const [activeLink, setActiveLink] = useState(LinkItems[0].page);

  const navigate = useNavigate();

  const handleNavigation = (page: string) => {
    console.log(activeLink);
    setActiveLink(page);
    navigate(page);
  };
  return (
    <Box
      bg={useColorModeValue("primary", "primary")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 40 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="center">
        <Logo />
      </Flex>
      <Box textAlign="right" display={{ base: "flex", md: "none" }}>
        <IconButton
          variant="outline"
          aria-label="Fermer le menu"
          icon={<CloseIcon />}
          size="sm"
          onClick={onClose}
          position="absolute"
          top={2}
          right={2}
        />
      </Box>
      <Box mt={4}>
        {LinkItems.map((link) => (
          <NavItem
            onClick={() => handleNavigation(link.page)}
            sx={
              activeLink === link.page
                ? { bg: "secondary", color: "black" }
                : {}
            }
            key={link.name}
            icon={link.icon}
            linked={link.page}
            name={link.name}
          >
            {link.name}ssss
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  name: string;
  linked: string;
}
const NavItem = ({ icon, name, linked, ...rest }: NavItemProps) => {
  const location = useLocation();
  const isActive = location.pathname === linked;

  return (
    <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        direction="column"
        align="center"
        justify="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="white"
        _hover={{
          bg: "secondary",
          color: "black",
        }}
        {...rest}
      >
        {icon && <Icon mb="2" fontSize="26" _groupHover={{}} as={icon} />}
        <Text>{name}</Text>
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={"transparent"}
      borderBottomColor={useColorModeValue("gray.500", "gray.900")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        color="black"
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};
