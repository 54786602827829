import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Button, Link, Td, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { FaNotesMedical } from "react-icons/fa";
import { Customer } from "../../utils/Types";

interface Props {
  customer: Customer;
  onAddNote: (customer: Customer) => void;
  onEditCustomer: (id: number) => void;
  onDeleteCustomer: (customer: Customer) => void;
}

export const CustomerTableRow: React.FC<Props> = ({
  customer,
  onAddNote,
  onEditCustomer,
  onDeleteCustomer,
}) => {
  return (
    <Tr>
      <Td>
        {customer.first_name} {customer.last_name}
      </Td>
      <Td>
        {" "}
        <Link color="blue.600" href={`mailto:${customer.email}`}>
          {customer.email}
        </Link>
      </Td>
      <Td>
        <Link color="blue.600" href={`tel:${customer.phone_number}`}>
          {customer.phone_number}{" "}
        </Link>
      </Td>
      <Td>
        <Button
          colorScheme="blue"
          size="sm"
          onClick={() => onAddNote(customer)}
          mr={2}
        >
          <Tooltip label="Ajouter une note">
            <FaNotesMedical />
          </Tooltip>
        </Button>
        <Button
          colorScheme="green"
          size="sm"
          mr={2}
          onClick={() => onEditCustomer(customer.id)}
        >
          <EditIcon />
        </Button>
        <Button
          colorScheme="red"
          size="sm"
          onClick={() => onDeleteCustomer(customer)}
        >
          <DeleteIcon />
        </Button>
      </Td>
    </Tr>
  );
};
