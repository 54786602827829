import { Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";

export const CustomerTableHeader: React.FC = () => {
  return (
    <Thead>
      <Tr>
        <Th>Nom</Th>
        <Th>Email</Th>
        <Th>Téléphone</Th>
        <Th>Actions</Th>
      </Tr>
    </Thead>
  );
};
