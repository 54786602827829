import { SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React, { useState } from "react";

interface Props {
  onFilterChange: (filter: string) => void;
}

export const CustomerTableSearch: React.FC<Props> = ({ onFilterChange }) => {
  const [filter, setFilter] = useState("");

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilter(value);
    onFilterChange(value);
  };

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.300" />
      </InputLeftElement>
      <Input
        placeholder="Rechercher par nom, téléphone, mail"
        value={filter}
        onChange={handleFilterChange}
      />
    </InputGroup>
  );
};
