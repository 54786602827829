/*************/
/** IMPORTS **/
/*************/

import { login, logout } from "./Users";

import {
  ERROR_CODE_INVALID,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_INVALID_CREDENTIALS,
  ERROR_INVALID_USERNAME,
  ERROR_RESET_EXPIRED,
  ERROR_RESET_WRONG_UUID,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  ERROR_USER_DO_NO_EXIST,
  ERROR_WRONG_RESPONSE,
} from "./Errors";

/*************/
/** EXPORTS **/
/*************/

const ApiService = {
  // USERS
  login,
  logout,

  // ERRORS
  ERROR_INVALID_CREDENTIALS,
  ERROR_USER_DO_NO_EXIST,
  ERROR_EMAIL_ALREADY_TAKEN,
  ERROR_INVALID_USERNAME,
  ERROR_CODE_INVALID,
  ERROR_RESET_EXPIRED,
  ERROR_WRONG_RESPONSE,
  ERROR_RESET_WRONG_UUID,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
};

export default ApiService;
