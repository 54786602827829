import { Box, Image, useColorModeValue } from "@chakra-ui/react";
export const Logopdb = () => {
  const src = useColorModeValue(
    "https://helena-lerat.fr/_next/static/media/logo64.3b482b72.png",
    "https://helena-lerat.fr/_next/static/media/logo64.3b482b72.png"
  );

  return (
    <Box display="flex">
      <Image margin="auto" src={src} alt="Logo Place des bailleurs" />
    </Box>
  );
};
