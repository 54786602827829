import { Box, Button, Flex, HStack, Tooltip, useToast } from "@chakra-ui/react";
import { FiLogOut, FiRefreshCcw } from "react-icons/fi";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBar from "../../modules/customComponents/SideBar";
import { logout } from "../api/Users";
import { LOGIN_PAGE } from "../navigation/endpoints";
import AuthService from "./Service";

// ... autres imports et fonctions

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const location = useLocation();
  const isAuth = AuthService.isAuthenticated();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLogout = async () => {
    try {
      const response = await logout();
      console.log(response);
      navigate(LOGIN_PAGE);

      toast({
        title: "Success",
        description: "Vous avez bien été déconnecté",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Erreur inconnu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    console.log("Déconnexion");
  };

  return isAuth ? (
    <Box display="flex">
      <SideBar children={undefined} />
      <Box flex="1">
        <Flex justifyContent="flex-end" mt="2" mr="2">
          <HStack spacing="2">
            <Tooltip label="Rafraîchir" hasArrow>
              <Button colorScheme="blue" onClick={handleRefresh}>
                <FiRefreshCcw />
              </Button>
            </Tooltip>
            <Tooltip label="Déconnexion" hasArrow>
              <Button colorScheme="red" onClick={handleLogout}>
                <FiLogOut />
              </Button>
            </Tooltip>
          </HStack>
        </Flex>
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Navigate to="/connexion" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
