import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Table, Tbody, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { Customer } from "../../utils/Types";
import {
  API_APPOINTMENTS,
  API_CLIENTS,
  API_URL,
} from "../../utils/api/ApiPaths";
import { ModalNotes } from "../agenda/modal/ModalNotes";
import { CustomerTableHeader } from "./CustomerTableHeader";
import { CustomerTableRow } from "./CustomerTableRow";
import { CustomerTableSearch } from "./CustomerTableSearch";
import CustomerDelete from "./modal/CustomerDelete";
import CustomerModalCreate from "./modal/CustomerModalCreate";
import CustomerModalEdit from "./modal/CustomerModalEdit"; // import de la nouvelle modal

interface Props {
  customers: Customer[];
  onDataUpdated: any;
}

export const CustomerTable: React.FC<Props> = ({
  customers,
  onDataUpdated,
}) => {
  const [filter, setFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const toast = useToast();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  ); // ajout de la state selectedCustomer

  const handleSaveNotes = async (
    appointmentId: string,
    notes: string,
    selectedData: any
  ) => {
    // Enregistrez les notes dans votre état global ou base de données
    try {
      const updatedAppointment = await updateAppointment(
        appointmentId,
        notes,
        selectedData
      );
      console.log("Notes sauvegardées :", updatedAppointment);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des notes :", error);
    }
  };

  const updateAppointment = async (
    appointmentId: string,
    updatedNotes: string,
    selectedData: any
  ) => {
    try {
      const response = await fetch(
        API_URL +
          API_CLIENTS +
          "/" +
          selectedData.idClient +
          API_APPOINTMENTS +
          "/" +
          appointmentId,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ notes: updatedNotes }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to update appointment: ${response.status} - ${response.statusText}`
        );
      }
      // Affichez un toast lorsque la modification est effectuée
      toast({
        title: "Notes sauvegardées",
        description: "Les notes ont été mises à jour avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      const responseData = await response.json();
      onDataUpdated();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to update appointment: `);
    }
  };
  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };

  const saveDeleteCustomer = async (customer: Customer): Promise<Customer> => {
    try {
      const response = await fetch(API_URL + API_CLIENTS + "/" + customer.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: JSON.stringify(customer),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to save customer: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      onDataUpdated();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to save customer: `);
    }
  };

  const saveEditCustomer = async (customer: Customer): Promise<Customer> => {
    try {
      const response = await fetch(API_URL + API_CLIENTS + "/" + customer.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: JSON.stringify(customer),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to save customer: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      onDataUpdated();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to save customer: `);
    }
  };
  /** TODO passer dans le fichier d'api */
  const saveCustomer = async (customer: Customer): Promise<Customer> => {
    console.log(customer);

    try {
      const response = await fetch(API_URL + API_CLIENTS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",

        body: JSON.stringify(customer),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to save customer: ${response.status} - ${response.statusText}`
        );
      }

      const responseData = await response.json();
      onDataUpdated();
      return responseData;
    } catch (error) {
      throw new Error(`Failed to save customer: `);
    }
  };

  const filteredCustomers = customers.filter((customer) =>
    `${customer.first_name} ${customer.last_name} ${customer.phone_number} ${customer.email}`
      .toLowerCase()
      .includes(filter.toLowerCase())
  );

  const handleNewCustomerClick = () => {
    setIsModalOpen(true);
  };

  const handleDeleteCustomerClick = (customer: Customer) => {
    if (customer) {
      setSelectedCustomer(customer);
      setIsModalOpenDelete(true);
    }
  };

  const handleOpenNotesModal = (customer: Customer) => {
    console.log(customer);
    if (customer) {
      setSelectedCustomer(customer);
      setIsNotesModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsModalOpenEdit(false);
    setIsModalOpenDelete(false);
    setIsNotesModalOpen(false);
    setSelectedCustomer(null);
  };

  const handleEditCustomerClick = (id: number) => {
    const customer = customers.find((c) => c.id === id);
    if (customer) {
      setSelectedCustomer(customer);
      setIsModalOpenEdit(true);
    }
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={3}>
        <CustomerTableSearch onFilterChange={handleFilterChange} />

        <Button
          ml={4}
          leftIcon={<AddIcon />}
          variant="primaryButton"
          color="white"
          onClick={handleNewCustomerClick}
        >
          Nouveau client
        </Button>
      </Flex>

      <Table variant="simple">
        <CustomerTableHeader />
        <Tbody>
          {filteredCustomers.map((customer) => (
            <CustomerTableRow
              key={customer.id}
              customer={customer}
              onAddNote={handleOpenNotesModal}
              onEditCustomer={handleEditCustomerClick} // passer la fonction handleEditCustomerClick à la place de onEditCustomer
              onDeleteCustomer={handleDeleteCustomerClick}
            />
          ))}
        </Tbody>
      </Table>

      {/* condition pour afficher la modal d'ajout ou d'édition en fonction de la présence de selectedCustomer */}
      {selectedCustomer ? (
        <CustomerModalEdit
          isOpen={isModalOpenEdit}
          onClose={handleModalClose}
          onSave={saveEditCustomer}
          customer={selectedCustomer}
        />
      ) : (
        <CustomerModalCreate
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSave={saveCustomer}
        />
      )}
      {selectedCustomer && (
        <ModalNotes
          isOpen={isNotesModalOpen}
          onClose={handleModalClose}
          onSave={handleSaveNotes}
          selectedData={selectedCustomer}
          appointments={selectedCustomer.appointments}
        />
      )}
      <CustomerDelete
        isOpen={isModalOpenDelete}
        customer={selectedCustomer}
        onClose={handleModalClose}
        onDelete={saveDeleteCustomer}
      />
    </Box>
  );
};
