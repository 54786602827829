// Types
import { LoginType, ToLoginType, UserType } from "../Types";

// paths & errors
import { API_LOGIN, API_LOGOUT, API_URL } from "./ApiPaths";
import {
  ERROR_INVALID_CREDENTIALS,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  ERROR_WRONG_RESPONSE,
} from "./Errors";

// axios

/*****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * Login through the API
 * @param email
 * @param password
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_INVALID_CREDENTIALS
 * @error ERROR_UNKNOWN
 */
export const login = (data: ToLoginType): Promise<LoginType> => {
  console.log(data);
  return fetch(API_URL + API_LOGIN, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw ERROR_INVALID_CREDENTIALS;
        } else {
          throw ERROR_UNKNOWN;
        }
      }
      return response.json();
    })
    .then((responseData) => {
      if (responseData) {
        // process the response to return token & UserType
        try {
          const token: string = responseData.token;
          const id = parseInt(responseData.user.id);

          const email = responseData.user.email;
          const user: UserType = { id, email };

          return { token, user } as LoginType;
        } catch (error) {
          console.error("Converting data to User: FAILED >> " + error);
          throw ERROR_WRONG_RESPONSE;
        }
      } else {
        console.error("Missing Data in the response");
        throw ERROR_WRONG_RESPONSE;
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

function clearToken() {
  localStorage.removeItem("therapievieid");
}

/**
 * Logout through the API
 * @returns Token + User info bundled in a LoginType
 * @error ERROR_UNAUTHORIZED
 * @error ERROR_UNKNOWN
 */
export const logout = () => {
  return fetch(API_URL + API_LOGOUT, {
    credentials: "include",
  })
    .then((response) => {
      if (response.ok) {
        clearToken();
        return;
      }
      if (response.status === 401) {
        clearToken();
        throw ERROR_UNAUTHORIZED;
      }
      throw ERROR_UNKNOWN;
    })
    .catch((error) => {
      clearToken();
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
