import { Container } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CustomerTable } from "../../modules/tableCustomer/CustomerTable";
import { API_CLIENTS, API_URL } from "../../utils/api/ApiPaths";
import { Customer } from "../../utils/Types";

export const Clients = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [updateCount, setUpdateCount] = useState(0);

  const sortCustomers = (customers: Customer[]): Customer[] => {
    return customers.sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
      const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  };

  useEffect(() => {
    console.log("ici fectchh");
    const fetchCustomers = async () => {
      try {
        const response = await fetch(API_URL + API_CLIENTS);

        if (!response.ok) {
          throw new Error(
            `Failed to fetch customers: ${response.status} - ${response.statusText}`
          );
        }

        const responseData = await response.json();
        const sortedCustomers = sortCustomers(responseData);

        setCustomers(sortedCustomers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCustomers();
  }, [updateCount]);

  return (
    <Container py={{ base: "12", md: "12" }}>
      <CustomerTable
        customers={customers}
        onDataUpdated={() => setUpdateCount(updateCount + 1)}
      />
    </Container>
  );
};
