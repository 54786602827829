import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";

import { Box, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSave } from "react-icons/fi";
import { Appointments } from "../../../utils/Types";
// ... autres imports

interface PropsModal {
  isOpen: boolean;
  onClose: () => void;
  onSave: (appointmentId: string, notes: string, selectedData: any) => void;
  appointments: Appointments[] | undefined; // Modifiez cette ligne
  profilName?: string;
  selectedData?: any;
}
const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
export const ModalNotes: React.FC<PropsModal> = ({
  isOpen,
  onClose,
  onSave,
  appointments,
  profilName,
  selectedData,
}: PropsModal) => {
  // ... autres hooks
  const [tempNotes, setTempNotes] = useState<string[]>([]);

  useEffect(() => {
    setTempNotes(() =>
      appointments
        ? appointments.map((appointment) => appointment.notes || "")
        : []
    );
  }, [appointments]);
  const sortedAppointments = appointments?.sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  );
  const handleSave = (appointmentId: any, notes: string) => {
    console.log(selectedData);
    onSave(appointmentId, notes, selectedData);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          mx={{ base: 4, md: 15 }}
          maxW={{ base: "90vw", md: "40vw" }}
        >
          <ModalHeader>
            Notes des rendez-vous de {profilName} ({sortedAppointments?.length})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            maxHeight={(sortedAppointments?.length ?? 0) > 2 ? "60vh" : "auto"}
            overflowY="auto"
          >
            {sortedAppointments && sortedAppointments.length > 0 ? (
              <VStack spacing={4}>
                {sortedAppointments.map((appointment, index) => (
                  <Box
                    key={appointment.startDate}
                    borderWidth={1}
                    borderRadius="md"
                    p={4}
                  >
                    <Text fontWeight="bold">
                      {" "}
                      {formatDate(appointment.startDate)}
                    </Text>
                    <Textarea
                      width={{ base: "80vw", md: "35vw" }}
                      value={tempNotes[index]}
                      onChange={(e) => {
                        const newTempNotes = [...tempNotes];
                        newTempNotes[index] = e.target.value;
                        setTempNotes(newTempNotes);
                      }}
                      placeholder="Saisissez vos notes ici..."
                    />
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={() =>
                        handleSave(appointment.id, tempNotes[index])
                      }
                      mt="2"
                    >
                      <FiSave />
                    </Button>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Text>Ce client n'a jamais eu de rendez-vous.</Text>
            )}{" "}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              borderColor="red"
            >
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
