import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AGENDA_PAGE } from "../../utils/navigation/endpoints";

export default function Custom404() {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      alignItems="center"
      textAlign="center"
      py={2}
      px={6}
      height="100vh"
    >
      <Container>
        {" "}
        <Heading
          size={useBreakpointValue({ base: "xs", md: "sm" })}
          color="brand.900"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={2} mb={2}>
          Page introuvable
        </Text>
        <Text color={"gray.600"} mb={6}>
          La page que vous avez demandé est introuvable
        </Text>
        <Button variant={"gradient"} onClick={() => navigate(AGENDA_PAGE)}>
          Retourner à l&apos;accueil
        </Button>
      </Container>
    </Box>
  );
}
