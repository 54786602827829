import { Input, List, ListIcon, ListItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { API_CLIENTS, API_URL } from "../../utils/api/ApiPaths";
import { Customer } from "../../utils/Types";

export default function SearchCustomer({ onSelect }: any) {
  const [clients, setClients] = useState([]);
  const [recherche, setRecherche] = useState("");
  const [selectedClient, setSelectedClient] = useState<Customer | null>();

  // récupérer les clients depuis l'API au montage du composant
  useEffect(() => {
    fetch(API_URL + API_CLIENTS, { credentials: "include" })
      .then((response) => response.json())
      .then((data) => {
        // trier la liste des clients par ordre alphabétique
        const sortedClients = data.sort((a: Customer, b: Customer) =>
          a.first_name.localeCompare(b.first_name)
        );
        setClients(sortedClients);
      });
  }, []);

  // filtrer les clients par recherche
  const clientsFiltres = clients.filter(
    (client: any) =>
      client.first_name.toLowerCase().includes(recherche.toLowerCase()) ||
      client.last_name.toLowerCase().includes(recherche.toLowerCase()) ||
      client.email.toLowerCase().includes(recherche.toLowerCase())
  );

  const listeClients = selectedClient ? [selectedClient] : clientsFiltres;

  const handleSelectClient = (client: any) => {
    setSelectedClient(client);
    onSelect(client);
  };

  return (
    <>
      <Input
        type="text"
        placeholder="Rechercher dans mes clients..."
        value={
          selectedClient
            ? `${selectedClient.first_name} ${selectedClient.last_name}`
            : recherche
        }
        onChange={(e) => {
          setSelectedClient(null);
          setRecherche(e.target.value);
        }}
      />
      <List mt={4} spacing={3}>
        {listeClients.map((client: any) => (
          <ListItem
            key={client.id}
            onClick={() => handleSelectClient(client)}
            cursor="pointer"
          >
            <ListIcon as={FaUser} color="blue.500" />
            {client.first_name} {client.last_name}
          </ListItem>
        ))}
      </List>
    </>
  );
}
