import { Box, Text, VStack } from "@chakra-ui/react";

const Legend = () => {
  return (
    <VStack spacing={2} alignItems="flex-start" mt="4">
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#6B6B6B" mr="2" />
        <Text display="inline">Absence</Text>
      </Box>
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#FF7575" mr="2" />
        <Text display="inline">Première consultation adulte</Text>
      </Box>
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#CBA415" mr="2" />
        <Text display="inline">Suivi de consultation adulte</Text>
      </Box>
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#9D00BD" mr="2" />
        <Text display="inline">Première consultation ado / enfant</Text>
      </Box>
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#1377C3" mr="2" />
        <Text display="inline">Suivi de consultation ado / enfant</Text>
      </Box>
      <Box>
        <Box display="inline-block" w="8" h="2" bg="#2E5252" mr="2" />
        <Text display="inline">Autre</Text>
      </Box>
    </VStack>
  );
};
export default Legend;
