import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Agenda from "../../modules/agenda/Agenda";
import SearchCustomerMultiple from "../../modules/customComponents/CustomSearchMultiple";
import { Clients } from "../../pages/home/ClientsPage";
import { NotificationPage } from "../../pages/home/NotificationPage";
import { Login } from "../../pages/loginWithEmailPasswordOrGoogle/Login";
import Custom404 from "../../pages/notfound/404";
import PublicLayout from "../PublicLayout";
import ProtectedRoutes from "./../authentication/ProtectedRoutes";
import { AGENDA_PAGE, CLIENT_PAGE, LOGIN_PAGE, NOTIF_PAGE, RELATION_PAGE } from "./endpoints";
const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Custom404 />} />

        <Route element={<PublicLayout />}>
          {/** Authentification */}
          <Route path={LOGIN_PAGE} element={<Login />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          {/** App */}
          <Route path={AGENDA_PAGE} element={<Agenda />} />
          <Route path={CLIENT_PAGE} element={<Clients />} />
          <Route path={NOTIF_PAGE} element={<NotificationPage />} />
          <Route path={RELATION_PAGE} element={<SearchCustomerMultiple />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
