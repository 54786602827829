// TOKEN & COOKIES
import jwtDecode, { JwtPayload } from "jwt-decode";
import Cookies from "universal-cookie";

// API
import ApiService from "../api/Service";
import { LoginType, ToLoginType } from "../Types";

// COOKIE NAME
const ccCookieName = "therapievieid";

/****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * This reads the cookie "therapievieid" to gather the token
 * @returns the token as a string or null if not found
 */
const getTokenFromCookie = () => {
  const cookies = new Cookies();
  const token = cookies.get(ccCookieName);
  return token;
};

/**
 * This removes the cookie "therapievieid"
 */
const removeCookie = () => {
  const cookies = new Cookies();
  console.log("remove cookie");
  cookies.remove(ccCookieName);
  console.log("cookie removed");
};

/**
 * Clear local storage for stored token and user info
 */
const clearLocalStorage = () => {
  localStorage.removeItem("therapievieid");
  localStorage.removeItem("user");
};

/**
 * Check the localStorage for the token and if it doesn't find it there, it checks the cookie.
 * @returns true if user is authenticated, false otherwise
 */
const isAuthenticated = (): boolean => {
  let token = localStorage.getItem("therapievieid");
  if (token === undefined || token === null || token === "") {
    // gathering the JWT from the cookie
    token = getTokenFromCookie();
    console.log("get token from cookie");
    console.log(token);
    if (token === undefined || token === null || token === "") return false;
    localStorage.setItem("therapievieid", token);
  }
  // we found the token so lets check it
  if (checkToken(token)) {
    // Token is valid
    return true;
  } else {
    // Token is invalid
    clearLocalStorage();
    return false;
  }
};

/**
 * Checks the token validity and expiration
 * @returns true if valid and unexpired, false otherwise
 */
const checkToken = (token: string) => {
  try {
    const decodedJwt = jwtDecode<JwtPayload>(token);
    if (decodedJwt === null) {
      return false;
    }
    if (decodedJwt.exp! * 1000 < Date.now()) {
      return false;
    } else return true;
  } catch (error) {
    console.error("checkToken: " + error);
    return false;
  }
};

/**
 * Login Function, called with a user identifies himself.
 * It calls the API with the credentials and, if there is an answer :
 * - set the user info in the local storage
 * - set the token in the local storage
 * @param email valid email string, will be used as main identifier
 * @param password
 */
const login = async (data: ToLoginType): Promise<LoginType> => {
  const loginInfo = await ApiService.login(data);
  localStorage.setItem("therapievieid", loginInfo.token);
  localStorage.setItem("user", JSON.stringify(loginInfo.user));
  return loginInfo;
};

/**
 * Logout function:
 * - user is logged out from the api
 * - cookie is removed
 * - clean the local storage
 */
const logout = async (): Promise<void> => {
  try {
    await ApiService.logout();
  } catch (error) {
    console.log(error);
  } finally {
    clearLocalStorage();
    removeCookie();
  }
};

/**
 * When we receive a 401, we have to log the user out ; so we just clear local data.
 */
const logout401 = () => {
  logout();
  console.error("Automatic logout due to the reception of a 401");
};

/**
 * @returns the user name stored in the local storage
 */
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user") as string);
};

const AuthService = {
  login,
  logout,
  logout401,
  getCurrentUser,
  isAuthenticated,
};

export default AuthService;
