import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { SelectedIdState } from "../Agenda";

interface PropsModal {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (objectId: SelectedIdState) => void;
  profilName: string;
  selectedId: SelectedIdState;
}

const DeleteAppointmentModal: React.FC<PropsModal> = ({
  isOpen,
  onClose,
  onDelete,
  profilName,
  selectedId,
}: PropsModal) => {
  const toast = useToast();

  const handleDelete = () => {
    onDelete(selectedId);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Supprimer le rendez-vous</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Êtes-vous sûr de vouloir{" "}
          <span style={{ color: "red" }}>supprimer</span> le rendez-vous de{" "}
          <b>{profilName} </b>?
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose} variant="outline" borderColor="red">
            Annuler
          </Button>
          <Button type="button" variant="primary" onClick={handleDelete}>
            Supprimer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAppointmentModal;
