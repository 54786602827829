import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Customer } from "../../../utils/Types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (customer: Customer) => void;
  customer?: Customer;
};

const schema = yup.object().shape({
  first_name: yup.string().required("Le prénom est requis"),
  last_name: yup.string().required("Le nom est requis"),
  email: yup
    .string()
    .email("Le format de l'email est invalide")
    .required("L'email est requis"),
  phone_number: yup.string().notRequired(),
  gender: yup.string().notRequired(),
  maiden_name: yup.string().notRequired(),
  birthdate: yup.string().notRequired(),
  address: yup.string().notRequired(),
  city: yup.string().notRequired(),
  zipcode: yup.string().notRequired(),
});

const CustomerModalEdit: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  customer,
}) => {
  const genderOptions = [{label : "Masculin", value: "M."}, {label: "Feminin", value: "Mme"}, {label: "Autres", value: "Mme/M."}];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Customer>({
    resolver: yupResolver(schema),
    defaultValues: customer,
  });

  const onSubmit = (data: Customer) => {
    onSave(data);
    onClose();
  };

  React.useEffect(() => {
    if (customer) {
      setValue("first_name", customer.first_name);
      setValue("last_name", customer.last_name);
      setValue("email", customer.email);
      setValue("phone_number", customer.phone_number);
      setValue("gender", customer.gender);
      setValue("maiden_name", customer.maiden_name);
      setValue("birthdate", customer.birthdate);
      setValue("address", customer.address);
      setValue("city", customer.city);
      setValue("zipcode", customer.zipcode);
    }
  }, [customer, setValue]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "90vw", md: "40vw" }}
      >
        <ModalHeader>
          <EditIcon /> Modifier le client {customer?.first_name} 
          {customer?.last_name}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Stack spacing={4}>
              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
                gap={4}
              >
                <FormControl isInvalid={!!errors.first_name}>
                  <FormLabel htmlFor="first_name">* Prénom</FormLabel>
                  <Input type="text" {...register("first_name")} />
                  {errors.first_name && (
                    <span style={{ color: "red" }}>
                      {errors.first_name.message}
                    </span>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.last_name}>
                  <FormLabel htmlFor="last_name">* Nom</FormLabel>
                  <Input type="text" {...register("last_name")} />
                  {errors.last_name && (
                    <span style={{ color: "red" }}>
                      {errors.last_name.message}
                    </span>
                  )}
                </FormControl>
              </Grid>

              <FormControl isInvalid={!!errors.maiden_name}>
                <FormLabel>Nom de jeune fille</FormLabel>
                <Input {...register("maiden_name")} />
              </FormControl>

              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
                gap={4}
              >
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input type="email" {...register("email")} />
                  {errors.email && (
                    <span style={{ color: "red" }}>{errors.email.message}</span>
                  )}
                </FormControl>
                <FormControl isInvalid={!!errors.phone_number}>
                  <FormLabel htmlFor="phone_number">Téléphone</FormLabel>
                  <Input type="tel" {...register("phone_number")} />
                  {errors.phone_number && (
                    <span style={{ color: "red" }}>
                      {errors.phone_number.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <FormControl isInvalid={!!errors.birthdate}>
                <FormLabel>Date de naissance</FormLabel>
                <Input type="date" {...register("birthdate")} />
              </FormControl>
              <FormControl isInvalid={!!errors.gender}>
                <FormLabel>Genre</FormLabel>
                <Select {...register("gender")} variant="filled">
                  <option value="">Choisir une option</option>
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isInvalid={!!errors.address}>
                <FormLabel>Adresse</FormLabel>
                <Input {...register("address")} />
              </FormControl>

              <Grid
                templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
                gap={4}
              >
                <FormControl isInvalid={!!errors.city}>
                  <FormLabel>Ville</FormLabel>
                  <Input {...register("city")} />
                </FormControl>

                <FormControl isInvalid={!!errors.zipcode}>
                  <FormLabel>Code postal</FormLabel>
                  <Input {...register("zipcode")} />
                </FormControl>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="outline" borderColor={"red"}>
              Annuler
            </Button>
            <Button variant="primaryButton" color="white" ml={3} type="submit">
              Modifier
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default CustomerModalEdit;
